import * as React from 'react'
import Image from 'next/image'
import NextLink from 'next/link'
import { Box, Heading, Link, Text, useColorModeValue } from '@chakra-ui/react'

type PostItemProps = {
  title: string
  slug: string
  description: string
  thumbnailUrl: string
}

export function PostItem({ title, thumbnailUrl, description, slug }: PostItemProps) {
  return (
    <Box p={4} display={{ md: 'flex' }}>
      <Box flexShrink={0} width={{ md: 60 }}>
        <NextLink href={`/posts/[slug]`} as={`/posts/${slug}`} passHref>
          <Link>
            <Image
              //width={{ md: 60 }}
              //borderRadius='lg'
              layout="responsive"
              width={240}
              height={150}
              src={thumbnailUrl}
              alt={title}
              priority={true}
            />
          </Link>
        </NextLink>
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
        {/*
        <Text
          fontWeight='bold'
          textTransform='uppercase'
          fontSize='sm'
          letterSpacing='wide'
          color='teal.600'
        >
          Marketing
        </Text>
        */}
        <Heading size={'md'}>
          <NextLink href={`/posts/[slug]`} as={`/posts/${slug}`} passHref>
            <Link
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
              color={useColorModeValue('purple.600', 'purple.400')}
            >
              {title}
            </Link>
          </NextLink>
        </Heading>
        <Text mt={2} color="gray.500">
          {description}
        </Text>
      </Box>
    </Box>
  )
}
