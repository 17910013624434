import * as React from 'react'
import Head from 'next/head'
import {
  APP_DOMAIN,
  META_KEYWORDS,
  META_DESCRIPTION,
  //WEB_URL,
  APP_NAME,
  // APP_IMAGE,
  FACEBOOK_ADMIN_ID,
  FACEBOOK_PAGE_ID,
  FACEBOOK_APP_ID,
  APP_URL,
} from 'lib/config'
import { useRouter } from 'next/router'

type MetaProps = {
  title?: string
  keywords?: string
  description?: string
  url?: string
  app_name?: string
  app_domain?: string
  image?: string
}

const defaultProps: MetaProps = {
  keywords: META_KEYWORDS,
  description: META_DESCRIPTION,
  //url: WEB_URL,
  url: '',
  app_name: APP_NAME,
  app_domain: APP_DOMAIN,
  //image: APP_IMAGE,
}

const Meta: React.FC<MetaProps> = ({
  title: titleProps,
  keywords,
  description,
  url,
  app_name,
  app_domain,
  image,
}: MetaProps) => {
  const router = useRouter()
  const title = titleProps ? `${titleProps} - ${APP_NAME}` : `${APP_NAME}`
  const nUrl = !!url ? url : `${APP_URL}${router.asPath === '/' ? '' : router.asPath}`

  return (
    <Head>
      <link rel="shortcut icon" href="/favicon.ico" />
      <title>{title}</title>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />

      <link rel="canonical" href={nUrl} />

      <meta property="fb:admins" content={`${FACEBOOK_ADMIN_ID}`} />
      <meta property="fb:pages" content={`${FACEBOOK_PAGE_ID}`} />
      <meta property="fb:app_id" content={`${FACEBOOK_APP_ID}`} />

      <meta property="og:url" content={nUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      {!!image && <meta property="og:image" content={image} />}
      <meta property="og:site_name" content={app_name} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content={`@${app_name?.toLowerCase()}`} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:creator" content={`@${app_name?.toLowerCase()}`} />
      {!!image && <meta property="twitter:image:src" content={image} />}
      <meta property="twitter:domain" content={app_domain} />
    </Head>
  )
}

Meta.defaultProps = defaultProps

export default Meta
