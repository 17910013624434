import * as React from 'react'
import { Box } from '@chakra-ui/react'
// import { useMe } from "lib/hooks/useMe"
import { HomeLayout } from 'components/HomeLayout'
import { initializeApollo } from 'lib/apollo/client'
import { GetPostsDocument, Post, QueryMode, Status } from 'lib/graphql'
import { PER_PAGE } from 'lib/config'
import Pagination from 'components/Pagination'
import { useRouter } from 'next/router'
import Meta from 'components/Meta'
import { PostItem } from 'components/PostItem'

export default function Home(props: any) {
  // const { me } = useMe()
  const router = useRouter()

  const { posts } = props
  const page = Number(router.query.page || 1)
  const nextPageUrl = page * PER_PAGE < posts.count ? `?page=${page + 1}` : ''
  const prevPageUrl = page > 1 ? `?page=${page - 1}` : ''

  return (
    <Box maxW={'3xl'} marginX="auto" marginY="4">
      <Meta />

      {posts.items?.map((post: Post) => (
        <PostItem
          key={post.id}
          title={post.title}
          description={post.description ?? ''}
          slug={post.slug}
          thumbnailUrl={post.thumbnailUrl}
        />
      ))}

      {posts.count > posts.items?.length && <Pagination nextUrl={nextPageUrl} previousUrl={prevPageUrl} />}
    </Box>
  )
}

Home.getLayout = (page: React.ReactNode) => <HomeLayout>{page}</HomeLayout>

export async function getServerSideProps({ query }: any) {
  const page = query.page || 1
  const search = query.search || ''
  const apolloClient = initializeApollo({})

  const response = await apolloClient.query({
    query: GetPostsDocument,
    variables: {
      orderBy: { createdAt: 'desc' },
      skip: PER_PAGE * (page - 1),
      where: {
        status: { equals: Status.Publish },
        OR: [
          { title: { contains: search, mode: QueryMode.Insensitive } },
          { slug: { contains: search, mode: QueryMode.Insensitive } },
          { content: { contains: search, mode: QueryMode.Insensitive } },
        ],
      },
    },
  })

  return {
    props: {
      page,
      posts: response.data?.posts || {},
    },
  }
}
